
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
  },
  computed: {},
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const router = useRouter();

    const store = useStore();
    const pollsterController = store.state.ControllersModule.pollsterController;
    const swalNotification = new SwalNotification();

    const pollResponse = ref<PollResponseListModel[]>([]);
    const pollChatLogs = ref<PollMessageListModel[]>([]);

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: [t("modals.interview.completedInterview"), t("modals.interview.ongoingInterview"), t("modals.interview.canceledInterview")],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = [5, 4, 2];

    const getPoll = async () => {
      isLoading.value = true;

      const pollResponseModel: PollResponseListModel = {
        id: 146,
      };

      pollsterController
        .pollResponseList(pollResponseModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollResponse.value, response.getValue());
          } else {
            if (pollResponse.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getPollChatLogs = async () => {
      isLoading.value = true;

      const pollMessageListModel: PollMessageListModel = {
        poll: {
          id: 146,
        },
      };

      pollsterController
        .pollSessionMessages(pollMessageListModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(pollChatLogs.value, response.getValue());
          } else {
            if (pollChatLogs.value.length == 0) {
              router.push({ name: "500" });
            }
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case QuestionTypes.OPEN_ENDED:
          return "Açık Uçlu";
        case QuestionTypes.SINGLE_CHOICE:
          return "Tek Seçenekli";
        case QuestionTypes.MULTIPLE_CHOICE:
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
      getPoll();
      getPollChatLogs();
    });

    return {
      questionTypeLabel,
      pollResponse,
      pollChatLogs,
      isLoading,
      chartOptions,
      series,
    };
  },
});
